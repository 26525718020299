import React from "react";

export const Nav = () => {
  return (
    <header className="text-base py-4 mb-8 bg-gray-800 flex">
      <h1 className="max-w-[90vw] w-[var(--tubeWidth)] mx-auto text-base text-left text-gray-100 font-normal">
        Wallace Leaderboard
      </h1>
    </header>
  );
};
