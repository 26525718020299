import React, { useState, useEffect } from "react";
import { Leaderboard } from "./components/Leaderboard";
import { Nav } from "./components/Nav";
import { GlobalStyle } from "./components/GlobalStyle";
import { Wrapper } from "./components/Wrapper";
import { CountdownTimer } from "./components/CountdownTimer";
import { getFirestore, doc, getDoc } from "firebase/firestore";

function App() {
  const [entriesLoaded, setEntriesLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEntriesLoadedStatus = async () => {
      // Check if the override environment variable is set
      const overrideEntriesLoaded = process.env.REACT_APP_OVERRIDE_ENTRIES_LOADED === 'true';

      if (overrideEntriesLoaded) {
        setEntriesLoaded(true);
        setLoading(false);
        return;
      }

      const db = getFirestore();
      const settingsDoc = doc(db, "settings", "appConfig");
      const docSnap = await getDoc(settingsDoc);

      if (docSnap.exists()) {
        setEntriesLoaded(docSnap.data().entriesLoaded === "yes");
      }
      setLoading(false);
    };

    fetchEntriesLoadedStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <GlobalStyle />
      <Nav />
      <Wrapper>
        {entriesLoaded ? <Leaderboard /> : <CountdownTimer />}
      </Wrapper>
    </div>
  );
}

export default App;