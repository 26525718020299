import React from "react";

const CustomIcon = () => (
  <div className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10 flex items-center justify-center bg-white">
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002" />
</svg>

  </div>
);

export const CountdownTimer = () => {
  return (
    <div className="px-4 py-10 sm:px-6 lg:px-8">
      <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0">
        <div className="flex items-center gap-x-6">
          <CustomIcon />
          <h1>
            <div className="mt-1 text-base font-semibold leading-6 text-gray-900">The Wallace Leaderboard is dormant until the next major.</div>
            <div className="text-sm leading-6 text-gray-500">
              See you then!
            </div>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default function Example() {
  return (
    <div className="px-4 py-10 sm:px-6 lg:px-8">
      <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0">
        <div className="flex items-center gap-x-6">
          <CustomIcon />
          <h1>
            {/* ... rest of the component ... */}
          </h1>
        </div>
        {/* ... rest of the component ... */}
      </div>
    </div>
  )
}
