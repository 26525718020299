import React from "react";

export const EntryCell = ({ score, player }) => {
  const displayScore = score === "CUT" ? "MC" : score;
  return (
    <div className="entrycell">
      <span className="entrycell-score">{displayScore}</span>
      <span className="entrycell-player">{player}</span>
    </div>
  );
};
