import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const config = {
  apiKey: "AIzaSyCs-DMXAqoBBtG3zTOE4SB1MU1ctxR3P9w",
  authDomain: "golfscores-2d225.firebaseapp.com",
  databaseURL: "https://golfscores-2d225.firebaseio.com",
  projectId: "golfscores-2d225",
  storageBucket: "golfscores-2d225.appspot.com",
  messagingSenderId: "346649315874",
  appId: "1:346649315874:web:5a16579b8be4d9eac4d87b",
  measurementId: "G-L3Y9RS3RMH",
};

const app = initializeApp(config);
const db = getFirestore(app);
getAnalytics(app);

export { db };
