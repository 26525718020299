const Entries = [
  {
    EntryName: "Crowell #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Tiger Woods",
    Player4: "Tommy Fleetwood",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Crowell #2",
    Player1: "Scottie Scheffler",
    Player2: "Joaquin Niemann",
    Player3: "Cameron Smith",
    Player4: "Tom Kim",
    Player5: "Keegan Bradley",
  },
  {
    EntryName: "Crowell #3",
    Player1: "Patrick Cantlay",
    Player2: "Xander Schauffele",
    Player3: "Tommy Fleetwood",
    Player4: "Akshay Bhatia",
    Player5: "Ludvig Aberg",
  },
  {
    EntryName: "Crowell #4",
    Player1: "Matthieu Pavon",
    Player2: "Sahith Theegala",
    Player3: "Nacho Elvira",
    Player4: "Tony Finau",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Stow #1",
    Player1: "Scottie Scheffler",
    Player2: "Tommy Fleetwood",
    Player3: "Aaron Rai",
    Player4: "Jon Rahm",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Stow #2",
    Player1: "Scottie Scheffler",
    Player2: "Tommy Fleetwood",
    Player3: "Brooks Koepka",
    Player4: "Sepp Straka",
    Player5: "Collin Morikawa",
  },
  {
    EntryName: "Stow #3",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Si Woo Kim",
    Player4: "Viktor Hovland",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "Stow #4",
    Player1: "Ludvig Aberg",
    Player2: "Xander Schauffele",
    Player3: "Brooks Koepka",
    Player4: "Collin Morikawa",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "Stow #5",
    Player1: "Bryson DeChambeau",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Tyrrell Hatton",
    Player5: "Tom Kim",
  },
  {
    EntryName: "Glover #1",
    Player1: "Scottie Scheffler",
    Player2: "Robert Macintyre",
    Player3: "Brian Harman",
    Player4: "Tommy Fleetwood",
    Player5: "Adam Scott",
  },
  {
    EntryName: "Glover #2",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Brooks Koepka",
    Player4: "Collin Morikawa",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Glover #3",
    Player1: "Ludvig Aberg",
    Player2: "Xander Schauffele",
    Player3: "Jon Rahm",
    Player4: "Tyrrell Hatton",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Glover #4",
    Player1: "Scottie Scheffler",
    Player2: "tony Finau",
    Player3: "Patrick Cantlay",
    Player4: "Cameron Smith",
    Player5: "Sahith Theegala",
  },
  {
    EntryName: "Dickerson #1",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Tommy Fleetwood",
    Player5: "Tony Finau",
  },
  {
    EntryName: "Dickerson #2",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Aberg",
    Player3: "Jon Rahm",
    Player4: "Robert Macintyre",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "Fonner #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Robert Macintyre",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "Fonner #2",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Collin Morikawa",
    Player4: "Patrick Cantlay",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Fonner #3",
    Player1: "Ludvig Aberg",
    Player2: "Xander Schauffele",
    Player3: "Brooks Koepka",
    Player4: "Viktor Hovland",
    Player5: "Sahith Theegala",
  },
  {
    EntryName: "Bono #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Tyrrell Hatton",
    Player5: "Sahith Theegala",
  },
  {
    EntryName: "B. Williams #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Sungjae Im",
    Player4: "Aaron Rai",
    Player5: "Corey Conners",
  },
  {
    EntryName: "B. Williams #2",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Tyrrell Hatton",
    Player4: "Tony Finau",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "B. Williams #3",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Collin Morikawa",
    Player4: "Tony Finau",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "B. Williams #4",
    Player1: "Collin Morikawa",
    Player2: "Sungjae Im",
    Player3: "Aaron Rai",
    Player4: "Corey Conners",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "Chan #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Viktor Hovland",
    Player5: "Robert Macintyre",
  },
  {
    EntryName: "Chan #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Tommy Fleetwood",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "Chan #3",
    Player1: "Bryson DeChambeau",
    Player2: "Xander Schauffele",
    Player3: "Collin Morikawa",
    Player4: "Jon Rahm",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "A. Hill #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Adam Scott",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "M. Taylor #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Tommy Fleetwood",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "M. Taylor #2",
    Player1: "Scottie Scheffler",
    Player2: "Tyrrell Hatton",
    Player3: "Cameron Smith",
    Player4: "Tom Kim",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "J. Fazio #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Tommy Fleetwood",
    Player4: "Viktor Hovland",
    Player5: "Tony Finau",
  },
  {
    EntryName: "J. Fazio #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Tyrrell Hatton",
    Player4: "Shane Lowry",
    Player5: "jon Rahm",
  },
  {
    EntryName: "J. Fazio #3",
    Player1: "Xander Schauffele",
    Player2: "Bryson DeChambeau",
    Player3: "Brooks Koepka",
    Player4: "Patrick Cantlay",
    Player5: "Wyndham Clark",
  },
  {
    EntryName: "J. Fazio #4",
    Player1: "Hideki Matsuyama",
    Player2: "Tom Kim",
    Player3: "Min Woo Lee",
    Player4: "Sungjae Im",
    Player5: "Si Woo Kim",
  },
  {
    EntryName: "J. Fazio #5",
    Player1: "Scottie Scheffler",
    Player2: "Robert Macintyre",
    Player3: "Adam Scott",
    Player4: "Sahith Theegala",
    Player5: "Matthieu Pavon",
  },
  {
    EntryName: "T. O'Neal #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Brooks Koepka",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "DJ Allen #1",
    Player1: "Bryson DeChambeau",
    Player2: "Rory McIlroy",
    Player3: "Shane Lowry",
    Player4: "Collin Morikawa",
    Player5: "Tom Kim",
  },
  {
    EntryName: "C. Sampson #1",
    Player1: "Ludvig Aberg",
    Player2: "Bryson DeChambeau",
    Player3: "Collin Morikawa",
    Player4: "Cameron Smith",
    Player5: "Cameron Young",
  },
  {
    EntryName: "C. Miller #1",
    Player1: "Bryson DeChambeau",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Sahith Theegala",
    Player5: "Wyndham Clark",
  },
  {
    EntryName: "C. Miller #2",
    Player1: "Scottie Scheffler",
    Player2: "Robert Macintyre",
    Player3: "Adam Scott",
    Player4: "Collin Morikawa",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Schuler #1",
    Player1: "Bryson DeChambeau",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Sahith Theegala",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "Varalla #1",
    Player1: "Viktor Hovland",
    Player2: "Matt Fitzpatrick",
    Player3: "Tommy Fleetwood",
    Player4: "Ludvig Aberg",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "Schaller #1",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Brooks Koepka",
    Player5: "Tom Kim",
  },
  {
    EntryName: "Prehn #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Tyrrell Hatton",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Prehn #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Tommy Fleetwood",
    Player4: "Viktor Hovland",
    Player5: "Tony Finau",
  },
  {
    EntryName: "Prehn #3",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Aberg",
    Player3: "Hideki Matsuyama",
    Player4: "Sahith Theegala",
    Player5: "Tom Kim",
  },
  {
    EntryName: "D. Stein #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Brooks Koepka",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "D. Stein #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Jon Rahm",
    Player5: "Tom Kim",
  },
  {
    EntryName: "D. Stein #3",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Viktor Hovland",
    Player4: "Tyrrell Hatton",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "K. Wilson #1",
    Player1: "Scottie Scheffler",
    Player2: "Aaron Rai",
    Player3: "Adam Scott",
    Player4: "Akshay Bhatia",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "K. Wilson #2",
    Player1: "Ludvig Aberg",
    Player2: "Bryson DeChambeau",
    Player3: "Matt Fitzpatrick",
    Player4: "Patrick Cantlay",
    Player5: "Tom Kim",
  },
  {
    EntryName: "K. Wilson #3",
    Player1: "Wyndham Clark",
    Player2: "Sahith Theegala",
    Player3: "Cameron Smith",
    Player4: "Collin Morikawa",
    Player5: "Robert Macintyre",
  },
  {
    EntryName: "A. Justman #1",
    Player1: "Bryson DeChambeau",
    Player2: "Collin Morikawa",
    Player3: "Alex Noren",
    Player4: "Aaron Rai",
    Player5: "Robert Macintyre",
  },
  {
    EntryName: "J. Watts #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Cameron Smith",
    Player4: "Collin Morikawa",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "Balkin #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Cameron Smith",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "Balkin #2",
    Player1: "Scottie Scheffler",
    Player2: "Adam Scott",
    Player3: "Tommy Fleetwood",
    Player4: "Collin Morikawa",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "Conner #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Brooks Koepka",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Conner #2",
    Player1: "Scottie Scheffler",
    Player2: "Robert Macintyre",
    Player3: "Collin Morikawa",
    Player4: "Tommy Fleetwood",
    Player5: "jon Rahm",
  },
  {
    EntryName: "Longman #1",
    Player1: "Tom Kim",
    Player2: "Ludvig Aberg",
    Player3: "Rory McIlroy",
    Player4: "Aaron Rai",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "Pfeifle #1",
    Player1: "Scottie Scheffler",
    Player2: "Aaron Rai",
    Player3: "Collin Morikawa",
    Player4: "Shane Lowry",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Pfeifle #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Jon Rahm",
    Player4: "Robert Macintyre",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "A. Stein #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Sahith Theegala",
    Player4: "Jon Rahm",
    Player5: "Tony Finau",
  },
  {
    EntryName: "A. Stein #2",
    Player1: "Ludvig Aberg",
    Player2: "Bryson DeChambeau",
    Player3: "Adam Scott",
    Player4: "Collin Morikawa",
    Player5: "Joaquin Niemann",
  },
  {
    EntryName: "A. Stein #3",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Tommy Fleetwood",
    Player4: "cameron Young",
    Player5: "Louis Oosthuizen",
  },
  {
    EntryName: "A. Stein #4",
    Player1: "Scottie Scheffler",
    Player2: "Tommy Fleetwood",
    Player3: "Brooks Koepka",
    Player4: "Shane Lowry",
    Player5: "Tom Kim",
  },
  {
    EntryName: "Pinkston #1",
    Player1: "Scottie Scheffler",
    Player2: "Akshay Bhatia",
    Player3: "Tyrrell Hatton",
    Player4: "Robert Macintyre",
    Player5: "Tom Kim",
  },
  {
    EntryName: "Jerry Wheeler #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Aaron Rai",
    Player4: "Sungjae Im",
    Player5: "Collin Morikawa",
  },
  {
    EntryName: "Jerry wheeler #2",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Brooks Koepka",
    Player4: "Wyndham Clark",
    Player5: "Sungjae Im",
  },
  {
    EntryName: "Jerry Wheeler #3",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "robert macintyre",
    Player4: "Viktor Hovland",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "J. Kayer #1",
    Player1: "Ludvig Aberg",
    Player2: "Patrick Cantlay",
    Player3: "Xander Schauffele",
    Player4: "Tony Finau",
    Player5: "Louis Oosthuizen",
  },
  {
    EntryName: "P. Hudson #1",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Tyrrell Hatton",
    Player4: "Tony Finau",
    Player5: "Cameron Young",
  },
  {
    EntryName: "A. Luzarraga #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Brooks Koepka",
    Player4: "Collin Morikawa",
    Player5: "Tom Kim",
  },
  {
    EntryName: "J. Luzarraga #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "tony Finau",
    Player4: "Viktor Hovland",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "Hullett #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Ludvig Aberg",
    Player4: "Tyrrell Hatton",
    Player5: "Corey Conners",
  },
  {
    EntryName: "Harrison #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Tony Finau",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Pope #1",
    Player1: "Rory McIlroy",
    Player2: "Cameron Smith",
    Player3: "Brooks Koepka",
    Player4: "Bryson DeChambeau",
    Player5: "jon Rahm",
  },
  {
    EntryName: "Pope #2",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Viktor Hovland",
    Player4: "Tony Finau",
    Player5: "Tom Kim",
  },
  {
    EntryName: "Hannay #1",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Viktor Hovland",
    Player4: "Tony Finau",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Barclay #1",
    Player1: "Ludvig Aberg",
    Player2: "Xander Schauffele",
    Player3: "Collin Morikawa",
    Player4: "Padraig Harrington",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "Mittleman #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Patrick Cantlay",
    Player4: "Tommy Fleetwood",
    Player5: "Max Homa",
  },
  {
    EntryName: "Henderson #1",
    Player1: "Scottie Scheffler",
    Player2: "Adam Scott",
    Player3: "Sungjae Im",
    Player4: "Tommy Fleetwood",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "W. Tyler #1",
    Player1: "Adam Scott",
    Player2: "Ludvig Aberg",
    Player3: "Sahith Theegala",
    Player4: "Bryson DeChambeau",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "W. Tyler #2",
    Player1: "Scottie Scheffler",
    Player2: "Adam Scott",
    Player3: "Brian Harman",
    Player4: "Jon Rahm",
    Player5: "Louis Oosthuizen",
  },
  {
    EntryName: "W. Tyler #3",
    Player1: "Viktor Hovland",
    Player2: "Collin Morikawa",
    Player3: "Dean Burmester",
    Player4: "Scottie Scheffler",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "R. Rakow #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Viktor Hovland",
    Player4: "Brooks Koepka",
    Player5: "Tony Finau",
  },
  {
    EntryName: "Dormont #1",
    Player1: "Xander Schauffele",
    Player2: "Collin Morikawa",
    Player3: "Tyrrell Hatton",
    Player4: "Ludvig Aberg",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Dormont #2",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "cameron Young",
    Player4: "Aaron Rai",
    Player5: "Adam Scott",
  },
  {
    EntryName: "Tedeschi #1",
    Player1: "Collin Morikawa",
    Player2: "Rory McIlroy",
    Player3: "Bryson DeChambeau",
    Player4: "Patrick Cantlay",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Tedeschi #2",
    Player1: "Scottie Scheffler",
    Player2: "Tommy Fleetwood",
    Player3: "Keegan Bradley",
    Player4: "Collin Morikawa",
    Player5: "Wyndham Clark",
  },
  {
    EntryName: "Tedeschi #3",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Viktor Hovland",
    Player4: "Collin Morikawa",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "Tedeschi #4",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Shane Lowry",
    Player4: "Collin Morikawa",
    Player5: "Sungjae Im",
  },
  {
    EntryName: "Tedeschi #5",
    Player1: "Scottie Scheffler",
    Player2: "Brooks Koepka",
    Player3: "Tommy Fleetwood",
    Player4: "Sahith Theegala",
    Player5: "Matt Fitzpatrick",
  },
  {
    EntryName: "Tedeschi #6",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Aberg",
    Player3: "tony Finau",
    Player4: "Viktor Hovland",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Tedeschi #7",
    Player1: "Scottie Scheffler",
    Player2: "Hideki Matsuyama",
    Player3: "Patrick Cantlay",
    Player4: "Robert Macintyre",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "Tedeschi #8",
    Player1: "Xander Schauffele",
    Player2: "Ludvig Aberg",
    Player3: "tony Finau",
    Player4: "Sahith Theegala",
    Player5: "Tom Kim",
  },
  {
    EntryName: "Tedeschi #9",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Christiaan Bezuidenhout",
    Player4: "Tyrrell Hatton",
    Player5: "Brian Harman",
  },
  {
    EntryName: "Tedeschi #10",
    Player1: "Xander Schauffele",
    Player2: "Bryson DeChambeau",
    Player3: "Aaron Rai",
    Player4: "Corey Conners",
    Player5: "Alex Noren",
  },
  {
    EntryName: "Ross #1",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Collin Morikawa",
    Player4: "Brian Harman",
    Player5: "Tom Kim",
  },
  {
    EntryName: "Hornbeak #1",
    Player1: "Scottie Scheffler",
    Player2: "Tommy Fleetwood",
    Player3: "Collin Morikawa",
    Player4: "Viktor Hovland",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "Ensign #1",
    Player1: "Rory McIlroy",
    Player2: "Viktor Hovland",
    Player3: "tony Finau",
    Player4: "Bryson DeChambeau",
    Player5: "Louis Oosthuizen",
  },
  {
    EntryName: "L. Odom #1",
    Player1: "Rory McIlroy",
    Player2: "tony Finau",
    Player3: "Ludvig Aberg",
    Player4: "Tommy Fleetwood",
    Player5: "Adam Scott",
  },
  {
    EntryName: "T. Fisher #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Tommy Fleetwood",
    Player5: "Sahith Theegala",
  },
  {
    EntryName: "K. Barclay #1",
    Player1: "Collin Morikawa",
    Player2: "Jordan Spieth",
    Player3: "Scottie Scheffler",
    Player4: "Tom Kim",
    Player5: "Cameron Young",
  },
  {
    EntryName: "Annett #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Viktor Hovland",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Scarmardo #1",
    Player1: "Scottie Scheffler",
    Player2: "Matt Fitzpatrick",
    Player3: "Tommy Fleetwood",
    Player4: "Ewen Ferguson",
    Player5: "Abraham Ancer",
  },
  {
    EntryName: "Belleson #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "robert macintyre",
    Player4: "Shane Lowry",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Belleson #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Patrick Cantlay",
    Player4: "Brooks Koepka",
    Player5: "Robert Macintyre",
  },
  {
    EntryName: "Wallace #1",
    Player1: "Scottie Scheffler",
    Player2: "Viktor Hovland",
    Player3: "Shane Lowry",
    Player4: "Tony Finau",
    Player5: "Adam Scott",
  },
  {
    EntryName: "Wallace #2",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Collin Morikawa",
    Player4: "Jon Rahm",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "Wallace #3",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Viktor Hovland",
    Player4: "Robert Macintyre",
    Player5: "jon Rahm",
  },
  {
    EntryName: "Glieber #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Brooks Koepka",
    Player4: "Aaron Rai",
    Player5: "Collin Morikawa",
  },
  {
    EntryName: "Glieber #2",
    Player1: "Scottie Scheffler",
    Player2: "tony Finau",
    Player3: "Cameron Smith",
    Player4: "Tyrrell Hatton",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Nicholson #1",
    Player1: "Viktor Hovland",
    Player2: "Rory McIlroy",
    Player3: "Bryson DeChambeau",
    Player4: "Akshay Bhatia",
    Player5: "Matthieu Pavon",
  },
  {
    EntryName: "Nicholson #2",
    Player1: "Scottie Scheffler",
    Player2: "Cameron Smith",
    Player3: "Akshay Bhatia",
    Player4: "Matt Fitzpatrick",
    Player5: "Justin Rose",
  },
  {
    EntryName: "Nicholson #3",
    Player1: "Collin Morikawa",
    Player2: "Rory McIlroy",
    Player3: "Xander Schauffele",
    Player4: "Shane Lowry",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "Nicholson #4",
    Player1: "Scottie Scheffler",
    Player2: "tony Finau",
    Player3: "Tommy Fleetwood",
    Player4: "Davis Thompson",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Nicholson #5",
    Player1: "Scottie Scheffler",
    Player2: "Jason Day",
    Player3: "Patrick Cantlay",
    Player4: "Justin Rose",
    Player5: "Rasmus Højgaard",
  },
  {
    EntryName: "Nicholson #6",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Shane Lowry",
    Player4: "Tyrrell Hatton",
    Player5: "Brian Harman",
  },
  {
    EntryName: "Nicholson #7",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Patrick Cantlay",
    Player4: "Tony Finau",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "Nicholson #8",
    Player1: "Rory McIlroy",
    Player2: "Tyrrell Hatton",
    Player3: "cameron Young",
    Player4: "Sungjae Im",
    Player5: "Adam Scott",
  },
  {
    EntryName: "Nicholson #9",
    Player1: "Jon Rahm",
    Player2: "Xander Schauffele",
    Player3: "Patrick Cantlay",
    Player4: "Adam Scott",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Nicholson #10",
    Player1: "Bryson DeChambeau",
    Player2: "Xander Schauffele",
    Player3: "Sepp Straka",
    Player4: "Patrick Cantlay",
    Player5: "Collin Morikawa",
  },
  {
    EntryName: "Nicholson #11",
    Player1: "Scottie Scheffler",
    Player2: "Shane Lowry",
    Player3: "Alex Noren",
    Player4: "Aaron Rai",
    Player5: "Sahith Theegala",
  },
  {
    EntryName: "Nicholson #12",
    Player1: "Collin Morikawa",
    Player2: "Tom Kim",
    Player3: "Rory McIlroy",
    Player4: "Robert Macintyre",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "B. Odom #1",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Aberg",
    Player3: "Bryson DeChambeau",
    Player4: "Tyrrell Hatton",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "J. Deaver #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Tyrrell Hatton",
    Player4: "Collin Morikawa",
    Player5: "Sahith Theegala",
  },
  {
    EntryName: "D. Bennett #1",
    Player1: "Scottie Scheffler",
    Player2: "Jordan Spieth",
    Player3: "Collin Morikawa",
    Player4: "Brooks Koepka",
    Player5: "Robert Macintyre",
  },
  {
    EntryName: "M Wheeler #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Jon Rahm",
    Player5: "Cameron Young",
  },
  {
    EntryName: "M. Wheeler #2",
    Player1: "Scottie Scheffler",
    Player2: "Jordan Spieth",
    Player3: "Tommy Fleetwood",
    Player4: "Sahith Theegala",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "Womble #1",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Aberg",
    Player3: "Tyrrell Hatton",
    Player4: "Tom Kim",
    Player5: "Tony Finau",
  },
  {
    EntryName: "Womble #2",
    Player1: "Scottie Scheffler",
    Player2: "Tommy Fleetwood",
    Player3: "Adam Scott",
    Player4: "Matthieu Pavon",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "Womble #3",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "robert macintyre",
    Player4: "Tommy Fleetwood",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Byrd #1",
    Player1: "Tommy Fleetwood",
    Player2: "Viktor Hovland",
    Player3: "Scottie Scheffler",
    Player4: "Collin Morikawa",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "Byrd #2",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "tony Finau",
    Player4: "Patrick Cantlay",
    Player5: "Brian Harman",
  },
  {
    EntryName: "Sharp #1",
    Player1: "Scottie Scheffler",
    Player2: "Wyndham Clark",
    Player3: "Viktor Hovland",
    Player4: "Shane Lowry",
    Player5: "Collin Morikawa",
  },
  {
    EntryName: "Sharp #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Tom Kim",
    Player4: "Brian Harman",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "P. Ward #1",
    Player1: "Scottie Scheffler",
    Player2: "tony Finau",
    Player3: "robert macintyre",
    Player4: "Collin Morikawa",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "P. Ward #2",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Patrick Cantlay",
    Player4: "Tony Finau",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "P. Ward #3",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Hideki Matsuyama",
    Player4: "Tommy Fleetwood",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "P. Ward #4",
    Player1: "Scottie Scheffler",
    Player2: "Hideki Matsuyama",
    Player3: "tony Finau",
    Player4: "Akshay Bhatia",
    Player5: "Collin Morikawa",
  },
  {
    EntryName: "P. Ward #5",
    Player1: "Hideki Matsuyama",
    Player2: "Rory McIlroy",
    Player3: "Akshay Bhatia",
    Player4: "Aaron Rai",
    Player5: "Robert Macintyre",
  },
  {
    EntryName: "Skonieczny #1",
    Player1: "Ludvig Aberg",
    Player2: "Collin Morikawa",
    Player3: "Tommy Fleetwood",
    Player4: "Patrick Cantlay",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Meacham #1",
    Player1: "Tommy Fleetwood",
    Player2: "Shane Lowry",
    Player3: "Viktor Hovland",
    Player4: "Rory McIlroy",
    Player5: "jon Rahm",
  },
  {
    EntryName: "T. Anderson #1",
    Player1: "Ludvig Aberg",
    Player2: "Collin Morikawa",
    Player3: "Rory McIlroy",
    Player4: "Robert Macintyre",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "D. Ondocsin #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "robert macintyre",
    Player4: "Tyrrell Hatton",
    Player5: "Tony Finau",
  },
  {
    EntryName: "D. Ondocsin #2",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Collin Morikawa",
    Player4: "Tommy Fleetwood",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "M. Ondocsin #1",
    Player1: "Ludvig Aberg",
    Player2: "Xander Schauffele",
    Player3: "Collin Morikawa",
    Player4: "Tom Kim",
    Player5: "Sahith Theegala",
  },
  {
    EntryName: "A. Ondocsin #1",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Viktor Hovland",
    Player4: "Shane Lowry",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "S. Good #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "tony Finau",
    Player4: "Tommy Fleetwood",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "B. Good #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "tony Finau",
    Player4: "Shane Lowry",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "B. Good #2",
    Player1: "Ludvig Aberg",
    Player2: "Collin Morikawa",
    Player3: "Xander Schauffele",
    Player4: "Brooks Koepka",
    Player5: "Tom Kim",
  },
  {
    EntryName: "Ramer #1",
    Player1: "Scottie Scheffler",
    Player2: "Adam Scott",
    Player3: "Sungjae Im",
    Player4: "Tommy Fleetwood",
    Player5: "Brian Harman",
  },
  {
    EntryName: "Ramer #2",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Corey Conners",
    Player4: "Collin Morikawa",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "A. Fires #1",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Billy Horschel",
    Player5: "Tony Finau",
  },
  {
    EntryName: "A. Fires #2",
    Player1: "Xander Schauffele",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Wyndham Clark",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Senter #1",
    Player1: "Scottie Scheffler",
    Player2: "Patrick Cantlay",
    Player3: "Viktor Hovland",
    Player4: "Collin Morikawa",
    Player5: "Cameron Young",
  },
  {
    EntryName: "Senter #2",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Cameron Smith",
    Player4: "Patrick Cantlay",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "J. Davis #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Wyndham Clark",
    Player5: "Harris English",
  },
  {
    EntryName: "R. Davis #1",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Rory McIlroy",
    Player4: "Shane Lowry",
    Player5: "Wyndham Clark",
  },
  {
    EntryName: "R. Davis #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Wyndham Clark",
    Player4: "Tommy Fleetwood",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "J. Matthews #1",
    Player1: "Xander Schauffele",
    Player2: "Rory McIlroy",
    Player3: "Viktor Hovland",
    Player4: "Jon Rahm",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "P. Matthews #1",
    Player1: "Bryson DeChambeau",
    Player2: "Collin Morikawa",
    Player3: "Ludvig Aberg",
    Player4: "Viktor Hovland",
    Player5: "Tony Finau",
  },
  {
    EntryName: "Bobbora #1",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Brooks Koepka",
    Player4: "Tyrrell Hatton",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Bobbora #2",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Tommy Fleetwood",
    Player4: "Viktor Hovland",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "Bobbora #3",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Brooks Koepka",
    Player4: "Cameron Smith",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Bobbora #4",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Tyrrell Hatton",
    Player4: "Shane Lowry",
    Player5: "Matt Fitzpatrick",
  },
  {
    EntryName: "Bobbora #5",
    Player1: "Ludvig Aberg",
    Player2: "Bryson DeChambeau",
    Player3: "Brooks Koepka",
    Player4: "Viktor Hovland",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Kasperksi #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Tommy Fleetwood",
    Player5: "jon Rahm",
  },
  {
    EntryName: "Kasperksi #2",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Collin Morikawa",
    Player4: "Viktor Hovland",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "John Wheeler #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Wyndham Clark",
    Player4: "Aaron Rai",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "John Wheeler #2",
    Player1: "Scottie Scheffler",
    Player2: "Tyrrell Hatton",
    Player3: "robert macintyre",
    Player4: "Cameron Smith",
    Player5: "tom Kim",
  },
  {
    EntryName: "Paletta #1",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Collin Morikawa",
    Player4: "Aaron Rai",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "Paletta #2",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Aberg",
    Player3: "Collin Morikawa",
    Player4: "Tommy Fleetwood",
    Player5: "Robert Macintyre",
  },
  {
    EntryName: "Paletta #3",
    Player1: "Scottie Scheffler",
    Player2: "Shane Lowry",
    Player3: "Collin Morikawa",
    Player4: "Cameron Smith",
    Player5: "Corey Conners",
  },
  {
    EntryName: "M. Kassing #1",
    Player1: "Ludvig Aberg",
    Player2: "Rory McIlroy",
    Player3: "Tommy Fleetwood",
    Player4: "Sahith Theegala",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "M. Kassing #2",
    Player1: "Scottie Scheffler",
    Player2: "Collin Morikawa",
    Player3: "Tommy Fleetwood",
    Player4: "Tony Finau",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "E. Brown #1",
    Player1: "Ludvig Aberg",
    Player2: "Bryson DeChambeau",
    Player3: "Collin Morikawa",
    Player4: "Tommy Fleetwood",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "P. Brown #1",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Collin Morikawa",
    Player4: "Jon Rahm",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "G. Wheeler #1",
    Player1: "Scottie Scheffler",
    Player2: "tony Finau",
    Player3: "Collin Morikawa",
    Player4: "Tyrrell Hatton",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "G. Wheeler #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Hideki Matsuyama",
    Player4: "Brooks Koepka",
    Player5: "Aaron Rai",
  },
  {
    EntryName: "G. Wheeler #3",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Aberg",
    Player3: "Patrick Cantlay",
    Player4: "Tommy Fleetwood",
    Player5: "Wyndham Clark",
  },
  {
    EntryName: "B. Cooper #1",
    Player1: "Rory McIlroy",
    Player2: "Tommy Fleetwood",
    Player3: "Xander Schauffele",
    Player4: "Viktor Hovland",
    Player5: "Collin Morikawa",
  },
  {
    EntryName: "B. Cooper #2",
    Player1: "Scottie Scheffler",
    Player2: "Viktor Hovland",
    Player3: "Collin Morikawa",
    Player4: "Tony Finau",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "B. Cooper #3",
    Player1: "Scottie Scheffler",
    Player2: "Nicolai Højgaard",
    Player3: "Hideki Matsuyama",
    Player4: "Justin Rose",
    Player5: "Patrick Cantlay",
  },
];
export default Entries;
