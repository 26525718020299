import React, { useState, useEffect, useCallback } from "react";
import { LeaderboardTable } from "./LeaderboardTable";
import CalculateTotal from "../utilities/CalculateTotal";
import sortEntries from "../utilities/sortEntries";
import { sortObjectByKeys } from "../utilities/sortObjectByKeys";
import "../styles/leaderboard.css";
import "../styles/star.css";
import { db } from "../firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import Entries from "../Entries";

export const Leaderboard = () => {
  const [scores, setScores] = useState({ data: "empty" });
  const [leaderboardEntries, setLeaderboardEntries] = useState([]);
  const [isSorted, setIsSorted] = useState(false);
  const [highlightedEntries, setHighlightedEntries] = useState({});
  const [, setShowEntries] = useState(false);
  const [, setCurrentTournament] = useState("");

  const updateScoresAndEntries = useCallback((newScores) => {
    const sortedNewScores = sortObjectByKeys(newScores);
    const sortedOldScores = sortObjectByKeys(scores);

    if (JSON.stringify(sortedOldScores) !== JSON.stringify(sortedNewScores)) {
      const scoresWithTotals = Entries.map((x) => ({
        ...x,
        Total: CalculateTotal([
          newScores[x.Player1],
          newScores[x.Player2],
          newScores[x.Player3],
          newScores[x.Player4],
          newScores[x.Player5],
        ]),
      }));

      const missedCut = scoresWithTotals.filter((x) => x.Total === "MC");
      const madeCut = scoresWithTotals.filter((x) => x.Total !== "MC");
      const sortedEntries = sortEntries([...madeCut], newScores);

      setScores(newScores);
      setLeaderboardEntries([...sortedEntries, ...missedCut]);
      setIsSorted(true);
    }
  }, [scores]);

  const setupListeners = useCallback((currentTournament, settingsDocRef) => {
    // Listen for settings changes
    const unsubscribeSettings = onSnapshot(settingsDocRef, (snapshot) => {
      if (snapshot.exists()) {
        setShowEntries(snapshot.data().showEntries);
      }
    }, (error) => {
      console.error("Error listening to settings:", error);
    });

    // Listen for score changes
    const unsubscribeScores = onSnapshot(doc(db, `snapshots/${currentTournament}`), (snapshot) => {
      if (snapshot.exists()) {
        const newScores = snapshot.data();
        updateScoresAndEntries(newScores);
      }
    }, (error) => {
      console.error("Error listening to scores:", error);
    });

    // Return cleanup function
    return () => {
      unsubscribeSettings();
      unsubscribeScores();
    };
  }, [updateScoresAndEntries]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch current tournament
        const currentTournamentDoc = await getDoc(doc(db, "current", "tournament"));
        const fetchedCurrentTournament = currentTournamentDoc.data().tourneyName;

        // Fetch initial settings
        const settingsDocRef = doc(db, "settings", "general");
        const settingsDoc = await getDoc(settingsDocRef);
        const fetchedShowEntries = settingsDoc.exists() ? settingsDoc.data().showEntries : false;

        setCurrentTournament(fetchedCurrentTournament);
        setShowEntries(fetchedShowEntries);

        // Set up listeners
        setupListeners(fetchedCurrentTournament, settingsDocRef);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, [setupListeners]);

  const handleStarClick = useCallback((entryName) => {
    setHighlightedEntries((prevEntries) => ({
      ...prevEntries,
      [entryName]: !prevEntries[entryName],
    }));
  }, []);

  return (
    <>
      {!isSorted ? (
        "loading scores..."
      ) : (
        <LeaderboardTable
          leaderboardEntries={leaderboardEntries}
          scores={scores}
          handleStarClick={handleStarClick}
          highlightedEntries={highlightedEntries}
        />
      )}
    </>
  );
};