import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
:root {
  --gray100: #f7fafc;
  --gray200: #edf2f7;
  --gray300: #e2e8f0;
  --gray400: #cbd5e0;
  --gray500: #a0aec0;
  --gray600: #718096;
  --gray700: #4a5568;
  --gray800: #2d3748;
  --gray900: #1a202c;
  --orange100: #fffaf0;
  --orange200: #feebc8;
  --orange300: #fbd38d;
  --orange400: #f6ad55;
  --orange500: #ed8936;
  --orange600: #dd6b20;
  --orange700: #c05621;
  --orange800: #9c4221;
  --orange900: #7b341e;
  --yellowDark: #f2d024;
  --belowPar: #E53E3E;
  --abovePar: #38A169;
  --tubeWidth: 1260px;
}
* {
  box-sizing: border-box;
  margin: 0;
}

html,
body {
  margin: 0;
  color: var(--gray800);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
li {
  margin-top: 0.25rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--gray800);
  line-height: 1.1;
}

strong {
  color: var(--gray800);
}
.logo {
  font-weight: 700;
}

nav {
  background-color: var(--gray100);
  border-top: 6px solid var(--orange800);
  border-bottom: 1px solid var(--gray400);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem calc((98vw - var(--tubeWidth)) / 2);
}

main {
  max-width: 90vw;
  width: var(--tubeWidth);
  min-width: 740px;
  margin: 2rem auto 4rem;
}

.links {
  margin-top: 0;
}
.links span {
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  cursor: pointer;
}
.links span:hover {
  border-bottom: 2px solid var(--orange400);
}
`;
