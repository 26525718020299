export default function sortEntries(entries, scores) {
  return entries.sort((a, b) => {
    // Compare total scores
    if (a.Total > b.Total) return 1;
    if (a.Total < b.Total) return -1;

    // Loop over the players
    for (let i = 1; i <= 5; i++) {
      // Parse and normalize scores
      let aScore = parseScore(scores[a[`Player${i}`]]);
      let bScore = parseScore(scores[b[`Player${i}`]]);
      // Compare scores
      if (aScore > bScore) return 1;
      if (aScore < bScore) return -1;
    }

    return -1;
  });
}

function parseScore(score) {
  if (score === "CUT" || score === "WD") return 7000;
  if (score === "E") return 0;
  return parseInt(score);
}
